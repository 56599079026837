import React, { memo } from 'react';

import {
  Stack, Text
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import intl from 'react-intl-universal';
import './style.less';


import { LocIds } from '../../../../common/Globalization/IntlEnum';
import { CardActionBarProps } from '../../../../components';
import { BaseCardView } from '../../../../components/CardView/BaseCardView';
import { FormPanelMode } from '../../../../components/TrustPanel/types';
import { Requirement } from '../../../../models/RegulationManagement/Requirement';
import { notify } from '../../../../utils/notify';
import { RequirementDetailsPanel } from '../RequirementList/RequirementDetailsPanel';

interface IProps {
  requirement: Requirement;
  editDisabled?: boolean;
  deleteDisabled?: boolean;
}

export const RequirementCard: React.FC<IProps> = memo((props: IProps) => {
  const { requirement, editDisabled = false, deleteDisabled = true } = props;
  const [isEditPanelOpen, { setTrue: openEditPanel, setFalse: closeEditPanel }] = useBoolean(false);

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const onFeedbackSuccess = () => {
    notify.success(intl.get(LocIds.RegulationManagement.ThankYouMessage));
  };

  const actionBarProps: CardActionBarProps = {
    buttonGroupProps: {
      items: [
        {
          iconProps: {
            iconName: 'Edit'
          },
          text: intl.get(LocIds.Action.Edit),
          ariaLabel: intl.get(LocIds.Action.Edit),
          onClick: openEditPanel,
          disabled: editDisabled
        },
        {
          iconProps: {
            iconName: 'Delete'
          },
          text: intl.get(LocIds.Action.Delete),
          ariaLabel: intl.get(LocIds.Action.Delete),
          onClick: undefined,
          disabled: deleteDisabled
        },
        {
          iconProps: {
            iconName: 'Like'
          },
          onClick: onFeedbackSuccess,
        },
        {
          iconProps: {
            iconName: 'Dislike'
          },
          onClick: onFeedbackSuccess,
        }
      ],
    },
  };

  const onRenderRequirementDetails = () => {
    return (
      <Stack className='cardBody__Text'
        horizontal
        horizontalAlign='space-between'
        onClick={stopPropagation}
        onMouseDown={stopPropagation}>

        <Text >
          { requirement.Description }
        </Text>

      </Stack>
    );
  };


  return (
    <Stack horizontal horizontalAlign='space-between'>
      <BaseCardView
        actionBarProps={actionBarProps}
        title={requirement.Title}
        titleHorizontal={true}
        titleLabel={undefined}>
        { onRenderRequirementDetails() }
      </BaseCardView>
      <RequirementDetailsPanel editPanelState={FormPanelMode.Edit}
        isOpen={isEditPanelOpen}
        requirementBody={requirement.Description}
        requirementGuid={''}
        requirementTitle={requirement.Title}
        onDismiss={function (): void {
          closeEditPanel();
        }}
      />
    </Stack>
  );
});
