import intl from 'react-intl-universal';

import { LocIds } from '../../../common/Globalization/IntlEnum';

export enum Classification {
  None = 'None',
  AZOnly = 'AZOnly',
  CrossRegion = 'CrossRegion',
  CrossRegionAndCustomerEnabledCombined = 'CrossRegionAndCustomerEnabledCombined',
  CustomerEnabledDR = 'CustomerEnabledDR',
  DataRecoveryOnly = 'DataRecoveryOnly',
  Geographic = 'Geographic',
  ManualProcessService = 'ManualProcessService',
  MultiRegionStateless = 'MultiRegionStateless',
  OneInstance = 'OneInstance',
  SingleRegionIsolated = 'SingleRegionIsolated',
  SingleRegionWithWorkloads = 'SingleRegionWithWorkloads',
  SubService = 'SubService',
  ThirdPartyServices = 'ThirdPartyServices',
}

export const classificationMapping = (): Record<string, string> => {
  return {
    [Classification.None]: intl.get(LocIds.Label.None),
    [Classification.AZOnly]: intl.get(LocIds.FedHound.AZOnly),
    [Classification.CrossRegion]: intl.get(LocIds.FedHound.CrossRegion),
    [Classification.CrossRegionAndCustomerEnabledCombined]: intl.get(
      LocIds.FedHound.CrossRegionAndCustomerEnabledCombined
    ),
    [Classification.CustomerEnabledDR]: intl.get(
      LocIds.FedHound.CustomerEnabledDR
    ),
    [Classification.DataRecoveryOnly]: intl.get(
      LocIds.FedHound.DataRecoveryOnly
    ),
    [Classification.Geographic]: intl.get(LocIds.FedHound.Geographic),
    [Classification.ManualProcessService]: intl.get(
      LocIds.FedHound.ManualProcessService
    ),
    [Classification.MultiRegionStateless]: intl.get(
      LocIds.FedHound.CrossRegion
    ),
    [Classification.OneInstance]: intl.get(LocIds.FedHound.OneInstance),
    [Classification.SingleRegionIsolated]: intl.get(
      LocIds.FedHound.SingleRegionIsolated
    ),
    [Classification.SingleRegionWithWorkloads]: intl.get(
      LocIds.FedHound.SingleRegionWithWorkloads
    ),
    [Classification.SubService]: intl.get(LocIds.FedHound.SubService),
    [Classification.ThirdPartyServices]: intl.get(
      LocIds.FedHound.ThirdPartyServices
    ),
  };
};

export enum ComplianceStatus {
  None = '',
  Compliant = 'Compliant',
  CompliantWithGap = 'CompliantWithGap',
  NonCompliant = 'NonCompliant',
  PostApprovalRejected = 'PostApprovalRejected',
  RA = 'RA',
  RAExpired = 'RAExpired',
}

export const complianceStatusMapping = (): Record<string, string> => {
  return {
    [ComplianceStatus.None]: intl.get(LocIds.Label.None),
    [ComplianceStatus.Compliant]: intl.get(LocIds.FedHound.Compliant),
    [ComplianceStatus.CompliantWithGap]: intl.get(
      LocIds.FedHound.CompliantWithGap
    ),
    [ComplianceStatus.NonCompliant]: intl.get(LocIds.FedHound.NonCompliant),
    [ComplianceStatus.PostApprovalRejected]: intl.get(
      LocIds.FedHound.PostApprovalRejected
    ),
    [ComplianceStatus.RA]: intl.get(LocIds.FedHound.RA),
    [ComplianceStatus.RAExpired]: intl.get(LocIds.FedHound.RAExpired),
  };
};

export enum DueDateAging {
  NotApplicable = 'NotApplicable',
  LessThan90Days = 'LessThan90Days',
  LessThan60Days = 'LessThan60Days',
  LessThan30Days = 'LessThan30Days',
  PastDue = 'PastDue',
}

export const dueDateAgingMapping = (): Record<string, string> => {
  return {
    [DueDateAging.NotApplicable]: intl.get(LocIds.FedHound.NotApplicable),
    [DueDateAging.LessThan90Days]: intl.get(LocIds.FedHound.LessThan90Days),
    [DueDateAging.LessThan60Days]: intl.get(LocIds.FedHound.LessThan60Days),
    [DueDateAging.LessThan30Days]: intl.get(LocIds.FedHound.LessThan30Days),
    [DueDateAging.PastDue]: intl.get(LocIds.FedHound.PastDue),
  };
};

export enum AssessmentStatus {
  None = 'None',
  Approved = 'Approved',
  AwaitingApproval = 'AwaitingApproval',
  ReadyForSubmission = 'ReadyForSubmission',
  Rejected = 'Rejected',
  InProgress = 'InProgress',
}

export const assessmentStatusMapping = (): Record<string, string> => {
  return {
    [AssessmentStatus.None]: intl.get(LocIds.Label.None),
    [AssessmentStatus.Approved]: intl.get(LocIds.FedHound.Approved),
    [AssessmentStatus.AwaitingApproval]: intl.get(
      LocIds.FedHound.AwaitingApproval
    ),
    [AssessmentStatus.ReadyForSubmission]: intl.get(
      LocIds.FedHound.ReadyForSubmission
    ),
    [AssessmentStatus.Rejected]: intl.get(LocIds.FedHound.Rejected),
    [AssessmentStatus.InProgress]: intl.get(LocIds.FedHound.InProgress),
  };
};

export enum LifecycleStatus {
  None = 'None',
  InDevelopment = 'InDevelopment',
  PrivatePreview = 'PrivatePreview',
  PublicPreview = 'PublicPreview',
  GA = 'GA',
  ClosingDown = 'ClosingDown',
  Retired = 'Retired',
  InDevelopmentSPACE = 'InDevelopmentSPACE',
  PrivatePreviewSPACE = 'PrivatePreviewSPACE',
  PublicPreviewSPACE = 'PublicPreviewSPACE',
  ClosingDownSPACE = 'ClosingDownSPACE',
}

export const lifecycleStatusMapping = (): Record<string, string> => {
  return {
    [LifecycleStatus.None]: intl.get(LocIds.Label.None),
    [LifecycleStatus.InDevelopment]: intl.get(LocIds.FedHound.InDevelopment),
    [LifecycleStatus.PrivatePreview]: intl.get(LocIds.FedHound.PrivatePreview),
    [LifecycleStatus.PublicPreview]: intl.get(LocIds.FedHound.PublicPreview),
    [LifecycleStatus.GA]: intl.get(LocIds.FedHound.GA),
    [LifecycleStatus.ClosingDown]: intl.get(LocIds.FedHound.ClosingDown),
    [LifecycleStatus.Retired]: intl.get(LocIds.FedHound.Retired),
    [LifecycleStatus.InDevelopmentSPACE]: intl.get(
      LocIds.FedHound.InDevelopment
    ),
    [LifecycleStatus.PrivatePreviewSPACE]: intl.get(
      LocIds.FedHound.PrivatePreview
    ),
    [LifecycleStatus.PublicPreviewSPACE]: intl.get(
      LocIds.FedHound.PublicPreview
    ),
    [LifecycleStatus.ClosingDownSPACE]: intl.get(LocIds.FedHound.ClosingDown),
  };
};

export enum RenewalStatus {
  Submitted = 'Submitted',
  ValidCurrentCycle = 'ValidCurrentCycle',
  Initiated = 'Initiated',
  InitiatedManually = 'InitiatedManually',
  Expired = 'Expired',
  Historical = 'Historical',
}

export const renewalStatusMapping = (): Record<string, string> => {
  return {
    [RenewalStatus.Submitted]: intl.get(LocIds.FedHound.Submitted),
    [RenewalStatus.ValidCurrentCycle]: intl.get(
      LocIds.FedHound.ValidCurrentCycle
    ),
    [RenewalStatus.Initiated]: intl.get(LocIds.FedHound.Initiated),
    [RenewalStatus.InitiatedManually]: intl.get(
      LocIds.FedHound.InitiatedManually
    ),
    [RenewalStatus.Expired]: intl.get(LocIds.FedHound.Expired),
    [RenewalStatus.Historical]: intl.get(LocIds.FedHound.Historical),
  };
};

export enum Cloud {
  Mt = 'MT',
  GccMt = 'GCC, MT',
  DodMt = 'DoD, MT',
  Dod = 'DoD',
  Gcc = 'GCC',
  GccM = 'GCCM',
  GccHigh = 'GCCHigh',
  Unclassified = 'Unclassified',
  None = 'None',
}

export const cloudMapping = (): Record<string, string> => {
  return {
    [Cloud.Mt]: intl.get(LocIds.Label.Mt),
    [Cloud.GccMt]: intl.get(LocIds.Label.GccMt),
    [Cloud.DodMt]: intl.get(LocIds.Label.DoDMt),
    [Cloud.Dod]: intl.get(LocIds.Label.DoD),
    [Cloud.Gcc]: intl.get(LocIds.Label.Gcc),
    [Cloud.GccM]: intl.get(LocIds.Label.GccM),
    [Cloud.GccHigh]: intl.get(LocIds.Label.GccHigh),
    [Cloud.Unclassified]: intl.get(LocIds.Label.Unclassified),
    [Cloud.None]: intl.get(LocIds.FedHound.NoTest),
  };
};

export enum Certification {
  InitialComplianceOnboarded = 'InitialComplianceOnboarded',
  ComplianceOnboardingMT = 'ComplianceOnboardingMT',
  ISO = 'ISO',
  SOC = 'SOC',
  Gcc = 'GCC',
  GccHigh = 'GCCHigh',
  Dod = 'DoD',
}

export const certificationMapping = (): Record<string, string> => {
  return {
    [Certification.InitialComplianceOnboarded]: intl.get(
      LocIds.FedHound.InitialComplianceOnboarded
    ),
    [Certification.ComplianceOnboardingMT]: intl.get(
      LocIds.FedHound.ComplianceOnboardingMT
    ),
    [Certification.ISO]: intl.get(LocIds.FedHound.ISO),
    [Certification.SOC]: intl.get(LocIds.FedHound.SOC),
    [Certification.Gcc]: intl.get(LocIds.Label.Gcc),
    [Certification.GccHigh]: intl.get(LocIds.Label.GccHigh),
    [Certification.Dod]: intl.get(LocIds.Label.DoD),
  };
};

export enum AuditScopes {
  None = 'None',
  ISO = 'ISO',
  SOC = 'SOC',
  FedRamp = 'FedRamp',
}

export const auditScopesMapping = (): Record<string, string> => {
  return {
    [AuditScopes.None]: intl.get(LocIds.Label.None),
    [AuditScopes.ISO]: intl.get(LocIds.FedHound.ISO),
    [AuditScopes.SOC]: intl.get(LocIds.FedHound.SOC),
    [AuditScopes.FedRamp]: intl.get(LocIds.FedHound.FedRamp),
  };
};

export enum ValidForAudit {
  Yes = 'Yes',
  No = 'No'
}

export const validForAuditMapping = (): Record<string, string> => {
  return {
    [ValidForAudit.Yes]: intl.get(LocIds.Label.Yes),
    [ValidForAudit.No]: intl.get(LocIds.Label.No),
  };
};

export enum EligibilityResults {
  Eligible = 'Eligible',
  NotEligibleLifecycle = 'NotEligibleLifecycle',
  NotEligibleChampMissing = 'NotEligibleChampMissing',
  NotEligibleServiceType = 'NotEligibleServiceType',
  NotEligibleComponent = 'NotEligibleComponent',
}

export const eligibilityResultsMapping = (): Record<string, string> => {
  return {
    [EligibilityResults.Eligible]: intl.get(LocIds.FedHound.ServiceIsEligible),
    [EligibilityResults.NotEligibleLifecycle]: intl.get(
      LocIds.FedHound.ServiceNotEligibleLifecycle
    ),
    [EligibilityResults.NotEligibleChampMissing]: intl.get(
      LocIds.FedHound.ServiceNotEligibleChampmissing
    ),
    [EligibilityResults.NotEligibleServiceType]: intl.get(
      LocIds.FedHound.ServiceNotEligibleServiceType
    ),
    [EligibilityResults.NotEligibleComponent]: intl.get(
      LocIds.FedHound.ServiceNotEligibleComponent
    ),
  };
};

export enum YesNoRevoked {
  Yes = 'Yes',
  No = 'No',
  Revoked = 'Revoked',
}

export enum TestRenewalStatusAction {
  MarkValid = 'Mark Test Valid for Current Cycle',
  ForceRenew = 'Force Renew',
  MarkComplete = 'Mark Complete',
}

export enum FedHoundColumnKey {
  Service = 'Service',
  ServiceTreeId = 'ServiceTreeId',
  ValidForAudit = 'ValidForAudit',
  Division = 'Division',
  Organization = 'Organization',
  ServiceGroup = 'ServiceGroup',
  Classification = 'Classification',
  KnownClouds = 'KnownClouds',
  NumberOfPassingTestsInCloud = 'NumberOfPassingTestsInCloud',
  BcdrChamp = 'BcdrChamp',
  CurrentCycleStatus = 'CurrentCycleStatus',
  NextTestDate = 'NextTestDate',
  ServiceRenewalDate = 'ServiceRenewalDate',
  DueDateAging = 'DueDateAging',
  AssessmentStatus = 'AssessmentStatus',
  EnvironmentStatus = 'EnvironmentStatus',
  RenewalStatus = 'RenewalStatus',
  ServiceStatus = 'ServiceStatus',
  FairfaxLifecycle = 'FairfaxLifecycle',
  TestsAvailable = 'TestsAvailable',
  Certifications = 'Certifications',
  MissingGovTests = 'MissingGovTests',
  AnomalyAcknowledged = 'AnomalyAcknowledged',
  AcknowledgedDate = 'AcknowledgedDate',
  AnomalyAcknowledgementNotes = 'AnomalyAcknowledgementNotes',
  UpdatedBy = 'UpdatedBy',
  KnownGovSubscriptions = 'KnownGovSubscriptions',
  AdminActions = 'AdminActions',
  LastTestDate = 'LastTestDate',
  TestTitle = 'TestTitle',
  TestResult = 'TestResult',
  TestType = 'TestType',
  TestLevel = 'TestLevel',
  BcdrLink = 'BcdrLink',
  UpdatedDate = 'UpdatedDate',
  FailoverRegions = 'FailoverRegions',
  ISO = 'ISO',
  SOC = 'SOC',
  FedRamp = 'FedRamp',
  KnownParticipant = 'KnownParticipant',
}

export const getFedHoundColumnName = (option: FedHoundColumnKey): string => {
  const map: Record<FedHoundColumnKey, string> = {
    [FedHoundColumnKey.Service]: intl.get(LocIds.Label.Service),
    [FedHoundColumnKey.ServiceTreeId]: intl.get(
      LocIds.FedHound.ServiceTreeIdLinkToBCDR
    ),
    [FedHoundColumnKey.ValidForAudit]: intl.get(
      LocIds.FedHound.GoodSampleForAudit
    ),
    [FedHoundColumnKey.Division]: intl.get(LocIds.Label.Division),
    [FedHoundColumnKey.Organization]: intl.get(LocIds.Label.Organization),
    [FedHoundColumnKey.ServiceGroup]: intl.get(LocIds.Label.ServiceGroup),
    [FedHoundColumnKey.Classification]: intl.get(
      LocIds.FedHound.Classification
    ),
    [FedHoundColumnKey.KnownClouds]: intl.get(LocIds.FedHound.KnownClouds),
    [FedHoundColumnKey.NumberOfPassingTestsInCloud]: intl.get(
      LocIds.FedHound.NumberOfPassingTestsInCloud
    ),
    [FedHoundColumnKey.BcdrChamp]: intl.get(LocIds.Label.BcdrChamp),
    [FedHoundColumnKey.CurrentCycleStatus]: intl.get(
      LocIds.Label.CurrentCycleStatus
    ),
    [FedHoundColumnKey.NextTestDate]: intl.get(LocIds.Label.NextTestDate),
    [FedHoundColumnKey.ServiceRenewalDate]: intl.get(
      LocIds.FedHound.ServiceRenewalDate
    ),
    [FedHoundColumnKey.DueDateAging]: intl.get(LocIds.FedHound.DueDateAging),
    [FedHoundColumnKey.AssessmentStatus]: intl.get(
      LocIds.Label.AssessmentStatus
    ),
    [FedHoundColumnKey.EnvironmentStatus]: intl.get(
      LocIds.Label.EnvironmentStatus
    ),
    [FedHoundColumnKey.RenewalStatus]: intl.get(LocIds.Label.RenewalStatus),
    [FedHoundColumnKey.ServiceStatus]: intl.get(LocIds.Label.ServiceStatus),
    [FedHoundColumnKey.FairfaxLifecycle]: intl.get(
      LocIds.FedHound.FairfaxLifecycle
    ),
    [FedHoundColumnKey.TestsAvailable]: intl.get(
      LocIds.FedHound.TestsAvailable
    ),
    [FedHoundColumnKey.Certifications]: intl.get(LocIds.Label.Certifications),
    [FedHoundColumnKey.MissingGovTests]: intl.get(
      LocIds.FedHound.MissingGovTests
    ),
    [FedHoundColumnKey.AnomalyAcknowledged]: intl.get(
      LocIds.FedHound.AnomalyAcknowledged
    ),
    [FedHoundColumnKey.AcknowledgedDate]: intl.get(
      LocIds.FedHound.AcknowledgedDate
    ),
    [FedHoundColumnKey.AnomalyAcknowledgementNotes]: intl.get(
      LocIds.FedHound.AnomalyAcknowledgementNotes
    ),
    [FedHoundColumnKey.UpdatedBy]: intl.get(LocIds.Label.UpdatedBy),
    [FedHoundColumnKey.KnownGovSubscriptions]: intl.get(
      LocIds.FedHound.KnownGovSubscriptions
    ),
    [FedHoundColumnKey.AdminActions]: intl.get(LocIds.FedHound.AdminActions),
    [FedHoundColumnKey.LastTestDate]: intl.get(LocIds.Label.LastTestDate),
    [FedHoundColumnKey.TestTitle]: intl.get(LocIds.Label.TestTitle),
    [FedHoundColumnKey.TestResult]: intl.get(LocIds.Label.TestResult),
    [FedHoundColumnKey.TestType]: intl.get(LocIds.Label.TestType),
    [FedHoundColumnKey.TestLevel]: intl.get(LocIds.Label.TestLevel),
    [FedHoundColumnKey.BcdrLink]: intl.get(LocIds.Label.BcdrLink),
    [FedHoundColumnKey.UpdatedDate]: intl.get(LocIds.Label.UpdatedDate),
    [FedHoundColumnKey.FailoverRegions]: intl.get(
      LocIds.FedHound.FailoverRegions
    ),
    [FedHoundColumnKey.ISO]: intl.get(LocIds.FedHound.ISO),
    [FedHoundColumnKey.SOC]: intl.get(LocIds.FedHound.SOC),
    [FedHoundColumnKey.FedRamp]: intl.get(LocIds.FedHound.FedRamp),
    [FedHoundColumnKey.KnownParticipant]: intl.get(
      LocIds.FedHound.KnownParticipant
    ),
  };

  return map[option];
};

export enum SubscriptionCloud {
  Public = 'Public',
  Mooncake = 'Mooncake',
  Fairfax = 'Fairfax',
  Blackforest = 'Blackforest',
  USSec = 'USSec',
  USNat = 'USNat',
  Bleu = 'Bleu',
  Delos = 'Delos',
  GovSG = 'GovSG',
}

export const getSubscriptionCloud = (option: SubscriptionCloud): string => {
  const map: Record<SubscriptionCloud, string> = {
    [SubscriptionCloud.Public]: intl.get(LocIds.SubscriptionCloud.Public),
    [SubscriptionCloud.Mooncake]: intl.get(LocIds.SubscriptionCloud.Mooncake),
    [SubscriptionCloud.Fairfax]: intl.get(LocIds.SubscriptionCloud.Fairfax),
    [SubscriptionCloud.Blackforest]: intl.get(
      LocIds.SubscriptionCloud.Blackforest
    ),
    [SubscriptionCloud.USSec]: intl.get(LocIds.SubscriptionCloud.USSec),
    [SubscriptionCloud.USNat]: intl.get(LocIds.SubscriptionCloud.USNat),
    [SubscriptionCloud.Bleu]: intl.get(LocIds.SubscriptionCloud.Bleu),
    [SubscriptionCloud.Delos]: intl.get(LocIds.SubscriptionCloud.Delos),
    [SubscriptionCloud.GovSG]: intl.get(LocIds.SubscriptionCloud.GovSG),
  };

  return map[option];
};

export enum ServiceRing {
  NoRing = 'NoRing',
  Ring0 = 'Ring0',
  Ring1 = 'Ring1',
  Ring2 = 'Ring2',
  Ring3 = 'Ring3',
  RingLimited = 'RingLimited',
}

export const getServiceRing = (option: ServiceRing): string => {
  const map: Record<ServiceRing, string> = {
    [ServiceRing.NoRing]: intl.get(LocIds.ServiceRing.NoRing),
    [ServiceRing.Ring0]: intl.get(LocIds.ServiceRing.Ring0),
    [ServiceRing.Ring1]: intl.get(LocIds.ServiceRing.Ring1),
    [ServiceRing.Ring2]: intl.get(LocIds.ServiceRing.Ring2),
    [ServiceRing.Ring3]: intl.get(LocIds.ServiceRing.Ring3),
    [ServiceRing.RingLimited]: intl.get(LocIds.ServiceRing.RingLimited),
  };

  return map[option];
};

export enum ServiceHierarchyNodeType {
  Unknown = 'Unknown',
  Service = 'Service',
  Component = 'Component',
  MicroService = 'MicroService',
}

export const serviceHierarchyNodeTypeMapping = (): Record<string, string> => {
  return {
    [ServiceHierarchyNodeType.Unknown]: intl.get(LocIds.FedHound.ServiceHierarchyNodeTypeUnknown),
    [ServiceHierarchyNodeType.Service]: intl.get(LocIds.FedHound.ServiceHierarchyNodeTypeService),
    [ServiceHierarchyNodeType.Component]: intl.get(LocIds.FedHound.ServiceHierarchyNodeTypeComponent),
    [ServiceHierarchyNodeType.MicroService]: intl.get(LocIds.FedHound.ServiceHierarchyNodeTypeMicroService),
  };
};

export enum ServiceTypeClassification {
  Client = 'Client',
  Infrastructure = 'Infrastructure',
  LibrarySharedCode = 'LibrarySharedCode',
  ManualProcessService = 'ManualProcessService',
  OnlineService = 'OnlineService',
  OperatingSystemSoftware = 'OperatingSystemSoftware',
  PlatformService = 'PlatformService',
  SubscriptionContainer = 'SubscriptionContainer',
}


export const serviceTypeClassificationMapping = (): Record<string, string> => {
  return {
    [ServiceTypeClassification.Client]: intl.get(LocIds.FedHound.ServiceTypeClassificationClient),
    [ServiceTypeClassification.Infrastructure]: intl.get(LocIds.FedHound.ServiceTypeClassificationInfrastructure),
    [ServiceTypeClassification.LibrarySharedCode]: intl.get(LocIds.FedHound.ServiceTypeClassificationLibrarySharedCode),
    [ServiceTypeClassification.ManualProcessService]: intl.get(LocIds.FedHound.ServiceTypeClassificationManualProcessService),
    [ServiceTypeClassification.OnlineService]: intl.get(LocIds.FedHound.ServiceTypeClassificationOnlineService),
    [ServiceTypeClassification.OperatingSystemSoftware]: intl.get(LocIds.FedHound.ServiceTypeClassificationOperatingSystemSoftware),
    [ServiceTypeClassification.PlatformService]: intl.get(LocIds.FedHound.ServiceTypeClassificationPlatformService),
    [ServiceTypeClassification.SubscriptionContainer]: intl.get(LocIds.FedHound.ServiceTypeClassificationSubscriptionContainer),
  };
};

export enum EligibilityError {
  NotEligibleWrongBCL = 'NotEligibleWrongBCL',
  NotEligibleServiceType = 'NotEligibleServiceType',
  NotEligibleClassification = 'NotEligibleClassification',
  NotEligibleLifecycle = 'NotEligibleLifecycle',
  NotEligibleMissingChamp = 'NotEligibleMissingChamp',
  ServiceNotFound = 'ServiceNotFound',
}


export const eligibilityErrorsMapping = (): Record<string, string> => {
  return {
    [EligibilityError.NotEligibleWrongBCL]: intl.get(LocIds.FedHound.EligibilityErrorsNotEligibleWrongBCL),
    [EligibilityError.NotEligibleServiceType]: intl.get(LocIds.FedHound.EligibilityErrorsNotEligibleServiceType),
    [EligibilityError.NotEligibleClassification]: intl.get(LocIds.FedHound.EligibilityErrorsNotEligibleClassification),
    [EligibilityError.NotEligibleLifecycle]: intl.get(LocIds.FedHound.EligibilityErrorsNotEligibleLifecycle),
    [EligibilityError.NotEligibleMissingChamp]: intl.get(LocIds.FedHound.EligibilityErrorsNotEligibleMissingChamp),
    [EligibilityError.ServiceNotFound]: intl.get(LocIds.FedHound.EligibilityErrorsServiceNotFound)
  };
};

export enum SubstrateModel {
  None = 'None',
  A = 'A',
  B = 'B',
  B2 = 'B2',
  D = 'D',
}

export const getSubstrateModel = (option: SubstrateModel): string => {
  const map: Record<SubstrateModel, string> = {
    [SubstrateModel.None]: intl.get(LocIds.SubstrateModel.None),
    [SubstrateModel.A]: intl.get(LocIds.SubstrateModel.A),
    [SubstrateModel.B]: intl.get(LocIds.SubstrateModel.B),
    [SubstrateModel.B2]: intl.get(LocIds.SubstrateModel.B2),
    [SubstrateModel.D]: intl.get(LocIds.SubstrateModel.D),
  };
  return map[option];
};
