import React, { useState } from 'react';

import {
  IStackTokens, Label, Separator, Stack, TagPicker, TextField, Toggle
} from '@fluentui/react';

import intl from 'react-intl-universal';

import { CollapsableLabel } from './CollapsableLabel';

import { LocIds } from '../../../../common/Globalization/IntlEnum';


import { RegulationType } from '../../../../models';
import { RegulationContactType } from '../../../../models/RegulationManagement/RegulationContact';
import { RegulationDetails } from '../../../../models/RegulationManagement/RegulationDetails';
import './RegulationDetailsPage.css';

import { TrustAnalysisType } from '../../../../models/RegulationManagement/RegulationTrustAnalysis';


interface IMainRegulationDetailsProps {
  regulationDetails?: RegulationDetails
}

export const MainRegulationDetails: React.FC<IMainRegulationDetailsProps> = (props) => {

  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(true);
  const [isCertificationDetailsAccordionOpen, setIsCertificationDetailsAccordionOpen] = useState<boolean>(true);
  const [isPointsOfContactAccordionOpen, setIsPointsOfContactAccordionOpen] = useState<boolean>(true);
  const [isCommentsAccordionOpen, setIsCommentsAccordionOpen] = useState<boolean>(true);

  const getRegulationTypeUserFriendlyName = (regulationType: RegulationType): string => {

    switch (regulationType) {
    case RegulationType.RegulatoryInquiry:
      return intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsRegulationTypeRegulatoryInquiry);
    case RegulationType.Certification:
      return intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsRegulationTypeCertification);
    case RegulationType.LawRegulation:
      return intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsRegulationTypeLawRegulation);
    case RegulationType.Standard:
      return intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsRegulationTypeStandard);
    case RegulationType.PublicCommitment:
      return intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsRegulationTypePublicCommitment);
    default:
      return intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsRegulationTypeUnknown);
    }
  };

  const formatDate = (date: Date): string => {
    return new Date(date).toDateString();
  };

  const labelStyles = {
    root: {
      fontSize: '20px',
      fontWeight: 'bold',
      cursor: 'pointer'
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 20
  };

  // Styles for the stack when we need a little additional spacing between textfields/other elements
  const stackMarginStyles = {
    root: {
      marginBottom: '10px'
    }
  };

  return (
    <Stack styles={{
      root: {
        marginBottom: '2%'
      }
    }}
    tokens={stackTokens}>
      <CollapsableLabel
        isOpen={isAccordionOpen}
        label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetails)}
        labelStyles={labelStyles}
        onToggle={() => setIsAccordionOpen(!isAccordionOpen)}
      />
      <Separator className='custom-separator' />
      { isAccordionOpen && (
        <Stack horizontal
          tokens={{
            childrenGap: 20
          }}>
          <Stack.Item grow
            styles={{
              root: {
                width: '50%'
              }
            }}>
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsRegulationName)}
              readOnly
              value={props.regulationDetails?.regulation.Name || ''}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsDescription)}
              multiline
              readOnly
              resizable={false}
              rows={6}
              value={props.regulationDetails?.regulation.Description || ''}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsRequirementsOverview)}
              multiline
              readOnly
              resizable={false}
              rows={6}
              value={props.regulationDetails?.regulation.RequirementsOverview || ''}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsDocumentUrl)}
              readOnly
              value={props.regulationDetails?.regulation.DocumentUrl || ''}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsAdditionalComments)}
              multiline
              readOnly
              resizable={false}
              rows={6}
              value={props.regulationDetails?.regulation.AdditionalComments || ''}
            />
          </Stack.Item>
          <Stack.Item grow
            styles={{
              root: {
                width: '10%'
              }
            }}>
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsRegulationType)}
              readOnly
              value={props.regulationDetails?.regulation.Type ? getRegulationTypeUserFriendlyName(props.regulationDetails.regulation.Type) : ''}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsLifecycleStage)}
              readOnly
              value={props.regulationDetails?.regulation.LifecycleStage?.toString() || ''}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsCreatedByAccountGuid)}
              readOnly
              value={props.regulationDetails?.regulation.CreatedByAccountGuid || ''}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsCreatedDate)}
              readOnly
              value={props.regulationDetails?.regulation.CreatedDate ? formatDate(props.regulationDetails.regulation.CreatedDate) : ''}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsModifiedByAccountGuid)}
              readOnly
              value={props.regulationDetails?.regulation.ModifiedByAccountGuid || ''}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsModifiedDate)}
              readOnly
              value={props.regulationDetails?.regulation.ModifiedDate ? formatDate(props.regulationDetails.regulation.ModifiedDate) : ''}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsEffectiveDate)}
              readOnly
              value={props.regulationDetails?.regulation.EffectiveDate ? formatDate(props.regulationDetails.regulation.EffectiveDate) : ''}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsDueByDate)}
              readOnly
              value={props.regulationDetails?.regulation.DueByDate ? formatDate(props.regulationDetails.regulation.DueByDate) : ''}
            />

          </Stack.Item>
          <Stack.Item grow
            styles={{
              root: {
                width: '30%'
              }
            }}>
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsGoverningRegulatoryBody)}
              readOnly
              value={props.regulationDetails?.regulation.GoverningRegulatoryBody || ''}
            />
            <Label>{ intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsIndustries) }</Label>
            <TagPicker
              disabled
              selectedItems={props.regulationDetails?.regulation.ApplicableIndustries?.split(',').map((industry) => ({
                key: industry,
                name: industry
              })) || []}
              onResolveSuggestions={() => []}
            />
            <Label>{ intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsRegions) }</Label>
            <TagPicker
              disabled
              selectedItems={props.regulationDetails?.regulation.ApplicableRegions?.toString().split(',').map((region) => ({
                key: region,
                name: region
              })) || []}
              onResolveSuggestions={() => []}
            />
            <Label>{ intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsDivisions) }</Label>
            <TagPicker
              disabled
              selectedItems={props.regulationDetails?.regulation.ApplicableDivisions?.toString().split(',').map((division) => ({
                key: division,
                name: division
              })) || []}
              onResolveSuggestions={() => []}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsScope)}
              readOnly
              value={props.regulationDetails?.regulation.ApplicableEntity?.toString() || ''}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsIsSalesBlocker)}
              readOnly
              value={props.regulationDetails?.regulation.IsSalesBlocker?.toString() || ''}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsSalesBlockerDetails)}
              multiline
              readOnly
              rows={4}
              value={props.regulationDetails?.regulation.SalesBlockerDetails?.toString() || ''}
            />
            <Toggle
              checked={props.regulationDetails?.regulation.IsLawEnforced || false}
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsLawEnforced)}
              offText={intl.get(LocIds.Label.Yes)}
              onText={intl.get(LocIds.Label.No)}
            />
            <Label>{ intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsCategories) }</Label>
            <TagPicker
              disabled
              selectedItems={props.regulationDetails?.regulation.Categories?.toString().split(',').map((category) => ({
                key: category,
                name: category
              })) || []}
              onResolveSuggestions={() => []}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsM365TrustAnalysis)}
              readOnly
              value={props.regulationDetails?.regulationTrustAnalyisData.filter(trustAnalysis => trustAnalysis.TrustAnalysisType === TrustAnalysisType.M365)[0]?.TrustAnalysisValue.toString() || ''}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsMainDetailsCnAITrustAnalysis)}
              readOnly
              value={props.regulationDetails?.regulationTrustAnalyisData.filter(trustAnalysis => trustAnalysis.TrustAnalysisType === TrustAnalysisType.CnAI)[0]?.TrustAnalysisValue.toString() || ''}
            />
          </Stack.Item>
        </Stack>
      ) }

      <Stack horizontal
        tokens={{
          childrenGap: 20
        }}>

        <Stack.Item grow
          styles={{
            root: {
              width: '33%'
            }
          }}>
          <CollapsableLabel
            isOpen={isCertificationDetailsAccordionOpen}
            label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsCertificationDetails)}
            labelStyles={labelStyles}
            onToggle={() => setIsCertificationDetailsAccordionOpen(!isCertificationDetailsAccordionOpen)}
          />
          <Separator className='custom-separator' />
          { isCertificationDetailsAccordionOpen && (
            <Stack>
              <TextField
                disabled={props.regulationDetails?.regulation.Type !== RegulationType.Certification}
                label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsCertificationDetailsCertificationNeededDescription)}
                readOnly
                value={props.regulationDetails?.regulation.Type === RegulationType.Certification ? props.regulationDetails?.regulation.CertificationNeededDescription || '' : 'N/A'}
              />
              <TextField
                disabled={props.regulationDetails?.regulation.Type !== RegulationType.Certification}
                label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsCertificationDetailsCertificationAuthority)}
                readOnly
                value={props.regulationDetails?.regulation.Type === RegulationType.Certification ? props.regulationDetails?.regulation.CertificationAuthority || '' : 'N/A'}
              />
              <TextField
                disabled={props.regulationDetails?.regulation.Type !== RegulationType.Certification}
                label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsCertificationDetailsExistingCertificationDetails)}
                readOnly
                value={props.regulationDetails?.regulation.Type === RegulationType.Certification ? props.regulationDetails?.regulation.ExistingCertificationDetails || '' : 'N/A'}
              />
              <TextField
                disabled={props.regulationDetails?.regulation.Type !== RegulationType.Certification}
                label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsCertificationDetailsCertificationNonComplianceImplicationDetails)}
                readOnly
                value={props.regulationDetails?.regulation.Type === RegulationType.Certification ? props.regulationDetails?.regulation.CertificationNonComplianceImplicationDetails || '' : 'N/A'}
              />
            </Stack>
          ) }

        </Stack.Item>
        <Stack.Item grow
          styles={{
            root: {
              width: '33%'
            }
          }}>
          <CollapsableLabel
            isOpen={isPointsOfContactAccordionOpen}
            label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsPointsOfContact)}
            labelStyles={labelStyles}
            onToggle={() => setIsPointsOfContactAccordionOpen(!isPointsOfContactAccordionOpen)}
          />
          <Separator className='custom-separator' />
          { isPointsOfContactAccordionOpen && (
            <Stack>
              <Label>{ intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsPointsOfContactPrimaryCelaContacts) }</Label>
              { props.regulationDetails?.regulationContacts.filter(contact => contact.ContactTypeId === RegulationContactType.PrimaryCelaContact).map((contact) => (
                <Stack.Item styles={stackMarginStyles}>
                  <TextField
                    readOnly
                    value={contact.AccountGuid}
                  />
                </Stack.Item>
              )) }
              <Label>{ intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsPointsOfContactFrontlineCelaContacts) }</Label>
              { props.regulationDetails?.regulationContacts.filter(contact => contact.ContactTypeId === RegulationContactType.FrontlineCelaContact).map((contact) => (
                <Stack.Item styles={stackMarginStyles}>
                  <TextField
                    readOnly
                    value={contact.AccountGuid}
                  />
                </Stack.Item>
              )) }
              <Label>{ intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsPointsOfContactMicrosoftContacts) }</Label>
              { props.regulationDetails?.regulationContacts.filter(contact => contact.ContactTypeId === RegulationContactType.MicrosoftContact).map((contact) => (
                <Stack.Item styles={stackMarginStyles}>
                  <TextField
                    readOnly
                    value={contact.AccountGuid}
                  />
                </Stack.Item>
              )) }
              <TextField
                label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsPointsOfContactRelevantStakeholders)}
                readOnly
                value={props.regulationDetails?.regulation.RelevantStakeholders || ''}
              />
            </Stack>
          ) }
        </Stack.Item>
      </Stack>
      <Stack horizontal
        tokens={{
          childrenGap: 20
        }}>
        <Stack.Item styles={{
          root: {
            width: '50%'
          }
        }}>
          <CollapsableLabel
            isOpen={isCommentsAccordionOpen}
            label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsComments)}
            labelStyles={labelStyles}
            onToggle={() => setIsCommentsAccordionOpen(!isCommentsAccordionOpen)}
          />
          <Separator className='custom-separator' />
          { isCommentsAccordionOpen && (
            <Stack>
              <TextField
                label={intl.get(LocIds.RegulationManagement.RegulationDetailsMainRegulationDetailsComment)}
                multiline
                readOnly
                resizable={false}
                rows={6}
                value={props.regulationDetails?.regulation.Comment || ''}
              />
            </Stack>
          ) }
        </Stack.Item>
      </Stack>
    </Stack>
  );

};
