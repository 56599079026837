import './style.less';

import React from 'react';

import {
  Checkbox, Link, Persona, PersonaSize, Stack, Text
} from '@fluentui/react';
import classNames from 'classnames';
import intl from 'react-intl-universal';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  ComplianceAuditWorkload, Service
} from '../../models';
import { CollapsibleSection } from '../CollapsibleSection';

interface IProps {
  workload: ComplianceAuditWorkload;
  services: Service[];
  isGreyedOut?: boolean;
  selectedServiceIds: Set<number | string>;
  setSelectedServiceIds: (ids: Set<number | string>) => void;
  collapsed?: boolean;
  hideNoSelection?: boolean;
  primaryKey: 'ResponsiblePartyId' | 'ServiceTreeId',
  showAssignee: boolean;
  disabled?: boolean;
}

type ServiceId = number | string | null;

export const WorkloadSection: React.FC<IProps> = ({
  workload,
  services,
  isGreyedOut = false,
  selectedServiceIds,
  setSelectedServiceIds,
  collapsed,
  hideNoSelection = false,
  primaryKey,
  showAssignee,
  disabled = false,
}) => {

  const selectedServicesThisGroup = services.filter((s: Service) => {
    const id = s[primaryKey] as ServiceId;

    return id !== null ? selectedServiceIds.has(id) : false;
  });

  const isAllSelected = selectedServicesThisGroup.length === services.length;

  const toggleSelectAll = () => {
    const serviceIds = new Set<number | string>(selectedServiceIds);

    services.forEach((s: Service) => {
      const id = s[primaryKey] as ServiceId;

      if (id !== null) {
        if (isAllSelected) {
          serviceIds.delete(id);
        } else {
          serviceIds.add(id);
        }
      }
    });

    setSelectedServiceIds(serviceIds);
  };

  const onChangeCheckbox = (serviceId: number | string, checked: boolean) => {
    const serviceIds = new Set<number | string>(selectedServiceIds);

    if (checked) {
      serviceIds.add(serviceId);
    } else {
      serviceIds.delete(serviceId);
    }

    setSelectedServiceIds(serviceIds);
  };

  if (services.length === 0 || (hideNoSelection && selectedServicesThisGroup.length === 0)) {
    return null;
  }

  const servicesJSX = services.map((service, i: number) => {
    const id = service[primaryKey] as ServiceId;

    if (!id) {
      return null;
    }

    const onChange = (_: any, checked?: boolean) => {
      onChangeCheckbox(id, checked ?? false);
    };

    return (
      <Stack
        className={classNames('workloadSection__option', {
          'workloadSection__option--grayed': isGreyedOut,
        })}
        horizontal
        horizontalAlign='space-between'
        key={id}>
        <Stack horizontal>
          <Checkbox
            checked={selectedServiceIds.has(id)}
            disabled={disabled}
            onChange={onChange}
          />
          <Stack>
            <Text>{ service.ShortName }</Text>
            { (showAssignee && service.PointOfContact && <Persona size={PersonaSize.size8} text={service.PointOfContact} />) }
          </Stack>
        </Stack>
        <Text>{ service.ServiceTreeId }</Text>
      </Stack>
    );
  });

  return (
    <CollapsibleSection
      bordered={false}
      chevronOnLeft
      collapsed={collapsed}
      title={`${workload.ShortName}${selectedServicesThisGroup.length > 0 ? ` (${selectedServicesThisGroup.length})` : ''}`}>
      <Stack className='workloadSection__options'
        tokens={{
          childrenGap: 12
        }}>
        { !disabled && (
          <Link className='selectAllButton' onClick={toggleSelectAll}>
            { isAllSelected ? intl.get(LocIds.Action.UnselectAll) : intl.get(LocIds.Action.SelectAll) }
          </Link>
        ) }
        { servicesJSX }
      </Stack>
    </CollapsibleSection>
  );
};
