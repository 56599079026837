import './style.less';
import React, { useState } from 'react';

import {
  Link, Stack, Text
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Toggle } from '@fluentui/react/lib/Toggle';
import _ from 'lodash';
import intl from 'react-intl-universal';

import { ServiceSelectionHeader } from './ServiceSelectionHeader';
import { ServiceSelectionShimmer } from './ServiceSelectionShimmer';

import { LocIds } from '../../common/Globalization/IntlEnum';
import {
  ComplianceAuditWorkload, Service
} from '../../models';
import { CollapsibleSection } from '../CollapsibleSection';
import { FormSection } from '../TrustLayout';
import { WorkloadSection } from '../WorkloadSection';

interface IProps {
  selectedServiceIds: Set<number | string>;
  onChange: (ids: Set<number | string>) => void;
  primaryKey: 'ResponsiblePartyId' | 'ServiceTreeId';
  // undefined represents loading
  allWorkloads?: ComplianceAuditWorkload[];
  // undefined represents loading
  allServices?: Service[];
  hideSelectAll?: boolean;
  showAssignee?: boolean;
  disabled?: boolean;
}

export const ServiceConfigBody: React.FC<IProps> = (props) => {
  const {
    selectedServiceIds,
    onChange,
    allServices,
    allWorkloads,
    hideSelectAll,
    showAssignee = false,
    disabled = false,
  } = props;

  const [keyword, setKeyword] = useState('');
  const [isAllCollapsed, { toggle: toggleAllCollapsed }] = useBoolean(false);
  const [hideNoSelection, { toggle: toggleHideNoSelection }] = useBoolean(false);

  if (!allWorkloads || !allServices) {
    return <ServiceSelectionShimmer />;
  }

  const isAllServicesSelected = selectedServiceIds.size === allServices.length;

  const toggleSelectAll = () => {
    const set = new Set<number | string>();

    if (!isAllServicesSelected) {
      const selectedItems = allServices
        .map((s: Service) => s[props.primaryKey])
        .filter((value) => value !== null) as (number | string)[];

      selectedItems.forEach((s: number | string) => set.add(s));
    }

    onChange(set);
  };

  const visibleServices = allServices.filter((s) =>
    (s.ShortName.toLowerCase().includes(keyword.toLowerCase()) || s.ServiceTreeId?.includes(keyword.toLowerCase()))
  );

  const ungroupedServices = visibleServices.filter(
    (s) =>
      !allWorkloads.some(
        (w) => w.ResponsiblePartyId === s.ParentResponsiblePartyId,
      ),
  );

  const headerActions = (
    <Stack tokens={{
      childrenGap: 12
    }}>
      <Stack horizontal horizontalAlign='space-between'>
        <Stack horizontal
          tokens={{
            childrenGap: 8
          }}
          verticalAlign='center'>
          <Text>
            { intl.get(LocIds.Label.NumberOfTotalServices, {
              num: selectedServiceIds.size,
              total: allServices.length,
            }) }
          </Text>
          { !hideSelectAll && allServices.length > 0 && (
            <Link onClick={toggleSelectAll}>
              { isAllServicesSelected ?
                intl.get(LocIds.Action.UnselectAll) :
                intl.get(LocIds.Action.SelectAll) }
            </Link>
          ) }
        </Stack>
        { allServices.length > 0 && (
          <Stack horizontal horizontalAlign='end' >
            <Toggle
              checked={hideNoSelection}
              className='serviceConfigBody__headerToggle'
              offText={intl.get(LocIds.Action.HideNoSelectionWorkloads)}
              onClick={toggleHideNoSelection}
              onText={intl.get(LocIds.Action.HideNoSelectionWorkloads)}
            />
          </Stack>
        ) }
      </Stack>
    </Stack>
  );

  return (
    <FormSection>
      <ServiceSelectionHeader keyword={keyword} onKeywordChange={setKeyword} />
      { headerActions }
      { visibleServices.length > 0 ? (
        <Stack>
          { (visibleServices.length > 0 && (!hideNoSelection || selectedServiceIds.size > 0)) && (
            <CollapsibleSection
              bordered={false}
              chevronOnLeft
              className='collapseAllWrapper'
              collapsed={isAllCollapsed}
              title={intl.get(LocIds.Label.ServiceName)}
              onToggle={toggleAllCollapsed}
            />
          ) }
          { allWorkloads.map((workload) => (
            <WorkloadSection
              collapsed={isAllCollapsed}
              disabled={disabled}
              hideNoSelection={hideNoSelection}
              key={workload.ResponsiblePartyId}
              primaryKey={props.primaryKey}
              selectedServiceIds={selectedServiceIds}
              services={visibleServices.filter((s) => s.ParentResponsiblePartyId === workload.ResponsiblePartyId)}
              setSelectedServiceIds={onChange}
              showAssignee={showAssignee}
              workload={workload}
            />
          )) }
          { ungroupedServices.length > 0 && (
            <WorkloadSection
              collapsed={isAllCollapsed}
              disabled={disabled}
              hideNoSelection={hideNoSelection}
              isGreyedOut={true}
              primaryKey={props.primaryKey}
              selectedServiceIds={selectedServiceIds}
              services={ungroupedServices}
              setSelectedServiceIds={onChange}
              showAssignee={showAssignee}
              workload={{
                ResponsiblePartyId: 0,
                ShortName: 'Ungrouped'
              }}
            />
          ) }
        </Stack>
      ) : (
        <Text>
          { _.isEmpty(keyword) ?
            intl.get(LocIds.Components.NoServices) :
            intl.get(LocIds.Components.NoServicesMatchGivenKeyword) }
        </Text>
      ) }
    </FormSection>
  );
};
