import React, { useState } from 'react';

import {
  IStackTokens, Label, Separator, Stack, TagPicker, TextField
} from '@fluentui/react';

import intl from 'react-intl-universal';

import { CollapsableLabel } from './CollapsableLabel';

import { LocIds } from '../../../../common/Globalization/IntlEnum';

import { RegulationDetails } from '../../../../models/RegulationManagement/RegulationDetails';
import './RegulationDetailsPage.css';
import { StatusType } from '../../../../models/RegulationManagement/RegulationStatus';

interface IRegulationExecutionDetailsProps {
    regulationDetails?: RegulationDetails
}

export const RegulationExecutionDetails: React.FC<IRegulationExecutionDetailsProps> = (props) => {

  const [isImpactDetailsAccordionOpen, setIsImpactDetailsAccordionOpen] = useState<boolean>(true);
  const [isPhasesAccordionOpen, setIsPhasesAccordionOpen] = useState<boolean>(true);
  const [isRelatedResourcesAccordionOpen, setIsRelatedResourcesAccordionOpen] = useState<boolean>(true);
  const [isNextStepsAccordionOpen, setIsNextStepsAccordionOpen] = useState<boolean>(true);
  const [isExecutiveSummaryAccordionOpen, setIsExecutiveSummaryAccordionOpen] = useState<boolean>(true);

  const labelStyles = {
    root: {
      fontSize: '20px', // Adjust the font size as needed
      fontWeight: 'bold',
      cursor: 'pointer'
    },
  };

  const stackTokens: IStackTokens = {
    childrenGap: 20
  };

  return (
    <Stack horizontal
      styles={{
        root: {
          marginBottom: '2%'
        }
      }}
      tokens={stackTokens}>
      <Stack.Item grow
        styles={{
          root: {
            width: '33%'
          }
        }}>
        <CollapsableLabel
          isOpen={isExecutiveSummaryAccordionOpen}
          label={intl.get(LocIds.RegulationManagement.RegulationDetailsExecutionExecutiveStatusSummary)}
          labelStyles={labelStyles}
          onToggle={() => setIsExecutiveSummaryAccordionOpen(!isExecutiveSummaryAccordionOpen)}
        />
        <Separator className='custom-separator' />
        { isExecutiveSummaryAccordionOpen && (
          <Stack>
            <TextField
              multiline
              readOnly
              rows={6}
              value={props.regulationDetails?.regulation.ExecutiveStatusSummary}
            />
          </Stack>
        ) }

        <CollapsableLabel
          isOpen={isNextStepsAccordionOpen}
          label={intl.get(LocIds.RegulationManagement.RegulationDetailsExecutionNextSteps)}
          labelStyles={labelStyles}
          onToggle={() => setIsNextStepsAccordionOpen(!isNextStepsAccordionOpen)}
        />
        <Separator className='custom-separator' />
        { isNextStepsAccordionOpen && (
          <Stack>
            <TextField
              multiline
              readOnly
              rows={6}
              value={props.regulationDetails?.regulation.NextSteps}
            />
          </Stack>
        ) }

        <CollapsableLabel
          isOpen={isImpactDetailsAccordionOpen}
          label={intl.get(LocIds.RegulationManagement.RegulationDetailsExecutionImpactDetails)}
          labelStyles={labelStyles}
          onToggle={() => setIsImpactDetailsAccordionOpen(!isImpactDetailsAccordionOpen)}
        />
        <Separator className='custom-separator' />
        { isImpactDetailsAccordionOpen && (
          <Stack>
            { props.regulationDetails?.regulationImpacts.map((impact) => (
              <Stack.Item>
                <Label style={{
                  fontSize: 16,
                  fontWeight: 'bold'
                }}>
                  Impact Entry
                </Label>
                <TextField
                  label={intl.get(LocIds.RegulationManagement.RegulationDetailsExecutionImpactType)}
                  readOnly
                  value={impact.ImpactType.toString()}
                />
                <Label>Impact Classification</Label>
                <TagPicker
                  disabled
                  selectedItems={impact.ImpactValue.toString().split(',').map((impactClassification) => ({
                    key: impactClassification,
                    name: impactClassification
                  })) || []}
                  onResolveSuggestions={() => []}
                />
              </Stack.Item>
            )) }
          </Stack>
        ) }
      </Stack.Item>
      <Stack.Item grow
        styles={{
          root: {
            width: '33%'
          }
        }}>
        <CollapsableLabel
          isOpen={isRelatedResourcesAccordionOpen}
          label={intl.get(LocIds.RegulationManagement.RegulationDetailsExecutionRelatedResources)}
          labelStyles={labelStyles}
          onToggle={() => setIsRelatedResourcesAccordionOpen(!isRelatedResourcesAccordionOpen)}
        />
        <Separator className='custom-separator' />
        { isRelatedResourcesAccordionOpen && (
          <Stack>
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsExecutionRelatedResourcesImplementationGuidance)}
              readOnly
              value={props.regulationDetails?.regulation.ImplementationGuidance}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsExecutionRelatedResourcesProductRequirements)}
              readOnly
              value={props.regulationDetails?.regulation.ProductRequirements}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsExecutionRelatedResourcesTeamsChannel)}
              readOnly
              value={props.regulationDetails?.regulation.TeamsChannel}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsExecutionRelatedResourcesUrlToCurrentVersion)}
              readOnly
              value={props.regulationDetails?.regulation.DocumentUrl}
            />
          </Stack>
        ) }
      </Stack.Item>
      <Stack.Item grow
        styles={{
          root: {
            width: '33%'
          }
        }}>
        <CollapsableLabel
          isOpen={isPhasesAccordionOpen}
          label={intl.get(LocIds.RegulationManagement.RegulationDetailsExecutionPhases)}
          labelStyles={labelStyles}
          onToggle={() => setIsPhasesAccordionOpen(!isPhasesAccordionOpen)}
        />
        <Separator className='custom-separator' />
        { isPhasesAccordionOpen && (
          <Stack>
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsExecutionPhasesImplementationGuidanceStatus)}
              readOnly
              value={props.regulationDetails?.regulationStatusData.filter(status => status.StatusType === StatusType.ImplementationGuidance)[0]?.StatusValue.toString() || ''}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsExecutionPhasesProductRequirementsStatus)}
              readOnly
              value={props.regulationDetails?.regulationStatusData.filter(status => status.StatusType === StatusType.ProductRequirements)[0]?.StatusValue.toString() || ''}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsExecutionPhasesEngineeringImplementationStatus)}
              readOnly
              value={props.regulationDetails?.regulationStatusData.filter(status => status.StatusType === StatusType.EngineeringImplementation)[0]?.StatusValue.toString() || ''}
            />
          </Stack>
        ) }
      </Stack.Item>


    </Stack>
  );

};
