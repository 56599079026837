import { IPanelProps } from '@fluentui/react';

import { IPrimaryButtonProps } from '../M365Button';

export interface TrustPanelProps
  extends Pick<
    IPanelProps,
    | 'className'
    | 'headerClassName'
    | 'isHiddenOnDismiss'
    | 'type'
    | 'onRenderFooterContent'
    | 'focusTrapZoneProps'
    | 'isLightDismiss'
  > {
  /**
   * Header text for the Panel.
   */
  headerText: string;

  /**
   * Whether the panel is displayed.
   * If true, will cause panel to stay open even if dismissed.
   * If false, will cause panel to stay hidden.
   * If undefined, will allow the panel to control its own visility through open/dismiss methods.
   * @defaultvalue undefined
   */
  isOpen?: boolean;

  primaryActionProps?: IPrimaryButtonProps | IPrimaryButtonProps[];
  onDismiss: () => void;
  hasCloseButton?: boolean;
  closeLabel?: string;
  preventClose?: boolean;
  showSpinnerInPrimaryButton?: boolean;
  showProcessingMessage?: boolean;
  processingMessage?: string;
  showErrorMessage?: boolean;
  errorMessage?: string;
  showErrorDismiss?: () => void;
  helperDocumentLink?: string;
}

export enum FormPanelMode {
  Edit = 'Edit',
  New = 'New',
}
