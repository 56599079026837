export interface Regulation {
    RegulationGuid: string;
    CreatedByAccountGuid: string;
    CreatedDate: Date;
    ModifiedByAccountGuid: string;
    ModifiedDate: Date;
    Comment: string;
    Description: string;
    DocumentUrl: string;
    Name: string;
    RequirementsOverview: string;
    SalesBlockerDetails: string;
    AdditionalComments: string;
    PrioritizationMeetingNotes: string;
    ExecutiveStatusSummary: string;
    NextSteps: string;
    SMTExecutiveStatusSummary: string;
    SMTNextSteps: string;
    GoverningRegulatoryBody: string;
    ImplementationGuidance: string;
    TeamsChannel: string;
    ProductRequirements: string;
    ApplicableIndustries: string;
    Categories: string;
    Type: RegulationType | null;
    LifecycleStage: LifecycleStage | null;
    ApplicableEntity: Entity | null;
    IsSalesBlocker: SalesBlockerType | null;
    ApplicableUserType: UserType | null;
    ApplicableRegions: Regions | null;
    ApplicableDivisions: Divisions | null;
    IsLawEnforced: boolean | null;
    EffectiveDate: Date | null;
    DueByDate: Date | null;
    CertificationNeededDescription: string;
    CertificationAuthority: string;
    ExistingCertificationDetails: string;
    CertificationNonComplianceImplicationDetails: string;
    RelevantStakeholders: string;
}

// export enum RegulationType {
//     RegulatoryInquiry = 0,
//     Certification = 1,
//     LawRegulation = 2,
//     Standard = 3,
//     PublicCommitment = 4
// }

export enum RegulationType {
    RegulatoryInquiry = 'RegulatoryInquiry',
    Certification = 'Certification',
    LawRegulation = 'LawRegulation',
    Standard = 'Standard',
    PublicCommitment = 'PublicCommitment'
}

export enum LifecycleStage {
    Effective = 0,
    InExternalDevelopment = 1,
    InReview = 2,
    Published = 3
}

export enum Entity {
    Direct = 0,
    Customer = 1
}

export enum SalesBlockerType {
    Unknown = 0,
    Yes = 1,
    No = 2
}

export enum UserType {
    ConsumerAndEnterprise = 0,
    Consumer = 1,
    Enterprise = 2
}

export enum Regions {
    APAC = 1 << 0,
    Canada = 1 << 1,
    CEE = 1 << 2,
    EU = 1 << 3,
    France = 1 << 4,
    Germany = 1 << 5,
    GCR = 1 << 6,
    Japan = 1 << 7,
    LATAM = 1 << 8,
    MEA = 1 << 9,
    UK = 1 << 10,
    US = 1 << 11,
    WE = 1 << 12,
    Global = 1 << 13
}

export enum Divisions {
    ED = 1 << 0, // 1
    MSSecurity = 1 << 1, // 2
    Gaming = 1 << 2, // 4
    SMT = 1 << 3, // 8
    CAI = 1 << 4 // 16
}
