import React, { useState } from 'react';

import {
  Dropdown, IStackTokens, Separator, Stack, TextField
} from '@fluentui/react';

import intl from 'react-intl-universal';

import { CollapsableLabel } from './CollapsableLabel';

import { LocIds } from '../../../../common/Globalization/IntlEnum';

import { RegulationDetails } from '../../../../models/RegulationManagement/RegulationDetails';
import './RegulationDetailsPage.css';
import { ScoreType } from '../../../../models/RegulationManagement/RegulationScore';
import { ScoringRationaleType } from '../../../../models/RegulationManagement/RegulationScoringRationale';

interface IRegulationPrioritizationDetailsProps {
    regulationDetails?: RegulationDetails
}

export const RegulationPrioritizationDetails: React.FC<IRegulationPrioritizationDetailsProps> = (props) => {

  const [isRegulatoryRiskScoringRationaleAccordionOpen, setIsRegulatoryRiskScoringRationaleAccordionOpen] = useState<boolean>(true);
  const [isEngineeringImpactScoringRationaleAccordionOpen, setIsEngineeringImpactScoringRationaleAccordionOpen] = useState<boolean>(true);
  const [isSalesBenefitScoringRationaleAccordionOpen, setIsSalesBenefitScoringRationaleAccordionOpen] = useState<boolean>(true);
  const [isPrioritizationMeetingNotesAccordionOpen, setIsPrioritizationMeetingNotesAccordionOpen] = useState<boolean>(true);
  const [isPrioritizationScoringAccordionOpen, setIsPrioritizationScoringAccordionOpen] = useState<boolean>(true);
  const [isRegulatoryRiskQuestionaireAccordionOpen, setIsRegulatoryRiskQuestionaireAccordionOpen] = useState<boolean>(true);
  const [isEngineeringImpactQuestionaireAccordionOpen, setIsEngineeringImpactQuestionaireAccordionOpen] = useState<boolean>(true);
  const [isSalesBenefitQuestionaireAccordionOpen, setIsSalesBenefitQuestionaireAccordionOpen] = useState<boolean>(true);

  const labelStyles = {
    root: {
      fontSize: '20px', // Adjust the font size as needed
      fontWeight: 'bold',
      cursor: 'pointer'
    },
  };

  const options = [
    {
      key: '1',
      text: intl.get(LocIds.Label.Yes)
    },
    {
      key: '2',
      text: intl.get(LocIds.Label.No)
    },
    {
      key: '3',
      text: intl.get(LocIds.Label.Unknown)
    }
  ];

  const stackTokens: IStackTokens = {
    childrenGap: 20
  };

  return (
    <Stack horizontal
      styles={{
        root: {
          marginBottom: '2%'
        }
      }}
      tokens={stackTokens}>

      <Stack.Item styles={{
        root: {
          width: '40%'
        }
      }}>
        <CollapsableLabel
          isOpen={isRegulatoryRiskQuestionaireAccordionOpen}
          label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationRegulatoryRiskQuestionaire)}
          labelStyles={labelStyles}
          onToggle={() => setIsRegulatoryRiskQuestionaireAccordionOpen(!isRegulatoryRiskQuestionaireAccordionOpen)}
        />
        <Separator className='custom-separator' />
        { isRegulatoryRiskQuestionaireAccordionOpen && (
          <Stack>
            { /* TODO: Potentially load these questions in dynamically at a later date */ }
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationRegulatoryRiskQuestionaireQuestion1)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationRegulatoryRiskQuestionaireQuestion2)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationRegulatoryRiskQuestionaireQuestion3)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationRegulatoryRiskQuestionaireQuestion4)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationRegulatoryRiskQuestionaireQuestion5)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationRegulatoryRiskQuestionaireQuestion6)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationRegulatoryRiskQuestionaireQuestion7)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationRegulatoryRiskQuestionaireQuestion8)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationRegulatoryRiskQuestionaireQuestion9)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationRegulatoryRiskQuestionaireQuestion10)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationRegulatoryRiskQuestionaireQuestion11)}
              options={options}
            />
          </Stack>
        ) }

        <CollapsableLabel
          isOpen={isEngineeringImpactQuestionaireAccordionOpen}
          label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationEngineeringImpactQuestionaire)}
          labelStyles={labelStyles}
          onToggle={() => setIsEngineeringImpactQuestionaireAccordionOpen(!isEngineeringImpactQuestionaireAccordionOpen)}
        />
        <Separator className='custom-separator' />
        { isEngineeringImpactQuestionaireAccordionOpen && (
          <Stack>
            { /* TODO: Potentially load these questions in dynamically at a later date */ }
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationEngineeringImpactQuestionaireQuestion1)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationEngineeringImpactQuestionaireQuestion2)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationEngineeringImpactQuestionaireQuestion3)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationEngineeringImpactQuestionaireQuestion4)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationEngineeringImpactQuestionaireQuestion5)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationEngineeringImpactQuestionaireQuestion6)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationEngineeringImpactQuestionaireQuestion7)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationEngineeringImpactQuestionaireQuestion8)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationEngineeringImpactQuestionaireQuestion9)}
              options={options}
            />
          </Stack>
        ) }

        <CollapsableLabel
          isOpen={isSalesBenefitQuestionaireAccordionOpen}
          label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationSalesBenefitQuestionaire)}
          labelStyles={labelStyles}
          onToggle={() => setIsSalesBenefitQuestionaireAccordionOpen(!isSalesBenefitQuestionaireAccordionOpen)}
        />
        <Separator className='custom-separator' />
        { isSalesBenefitQuestionaireAccordionOpen && (
          <Stack>
            { /* TODO: Potentially load these questions in dynamically at a later date */ }
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationSalesBenefitQuestionaireQuestion1)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationSalesBenefitQuestionaireQuestion2)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationSalesBenefitQuestionaireQuestion3)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationSalesBenefitQuestionaireQuestion4)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationSalesBenefitQuestionaireQuestion5)}
              options={options}
            />
            <Dropdown
              disabled
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationSalesBenefitQuestionaireQuestion6)}
              options={options}
            />
          </Stack>
        ) }
      </Stack.Item>

      <Stack.Item grow
        styles={{
          root: {
            width: '40%'
          }
        }}>
        <CollapsableLabel
          isOpen={isRegulatoryRiskScoringRationaleAccordionOpen}
          label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationRegulatoryRiskScoringRationale)}
          labelStyles={labelStyles}
          onToggle={() => setIsRegulatoryRiskScoringRationaleAccordionOpen(!isRegulatoryRiskScoringRationaleAccordionOpen)}
        />
        <Separator className='custom-separator' />
        { isRegulatoryRiskScoringRationaleAccordionOpen && (
          <TextField
            multiline
            readOnly
            rows={4}
            value={props.regulationDetails?.regulationScoringRationales.filter(rationale => rationale.ScoringRationaleType === ScoringRationaleType.RegulatoryRisk)[0]?.ScoringRationaleType?.toString() || ''}
          />
        ) }

        <CollapsableLabel
          isOpen={isEngineeringImpactScoringRationaleAccordionOpen}
          label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationEngineeringImpactScoringRationale)}
          labelStyles={labelStyles}
          onToggle={() => setIsEngineeringImpactScoringRationaleAccordionOpen(!isEngineeringImpactScoringRationaleAccordionOpen)}
        />
        <Separator className='custom-separator' />
        { isEngineeringImpactScoringRationaleAccordionOpen && (
          <TextField
            multiline
            readOnly
            rows={4}
            value={props.regulationDetails?.regulationScoringRationales.filter(rationale => rationale.ScoringRationaleType === ScoringRationaleType.RegulatoryRisk)[0]?.ScoringRationaleType?.toString() || ''}
          />
        ) }

        <CollapsableLabel
          isOpen={isSalesBenefitScoringRationaleAccordionOpen}
          label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationSalesBenefitScoringRationale)}
          labelStyles={labelStyles}
          onToggle={() => setIsSalesBenefitScoringRationaleAccordionOpen(!isSalesBenefitScoringRationaleAccordionOpen)}
        />
        <Separator className='custom-separator' />
        { isSalesBenefitScoringRationaleAccordionOpen && (
          <TextField
            multiline
            readOnly
            rows={4}
            value={props.regulationDetails?.regulationScoringRationales.filter(rationale => rationale.ScoringRationaleType === ScoringRationaleType.RegulatoryRisk)[0]?.ScoringRationaleType?.toString() || ''}
          />
        ) }

        <CollapsableLabel
          isOpen={isPrioritizationMeetingNotesAccordionOpen}
          label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationMeetingNotes)}
          labelStyles={labelStyles}
          onToggle={() => setIsPrioritizationMeetingNotesAccordionOpen(!isPrioritizationMeetingNotesAccordionOpen)}
        />
        <Separator className='custom-separator' />
        { isPrioritizationMeetingNotesAccordionOpen && (
          <TextField
            multiline
            readOnly
            rows={6}
            value={props.regulationDetails?.regulation.PrioritizationMeetingNotes || ''}
          />
        ) }
      </Stack.Item>
      <Stack.Item grow
        styles={{
          root: {
            width: '10%'
          }
        }}>
        <CollapsableLabel
          isOpen={isPrioritizationScoringAccordionOpen}
          label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationScoring)}
          labelStyles={labelStyles}
          onToggle={() => setIsPrioritizationScoringAccordionOpen(!isPrioritizationScoringAccordionOpen)}
        />
        <Separator className='custom-separator' />
        { isPrioritizationScoringAccordionOpen && (
          <Stack>
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationScoringRegulatoryRiskScore)}
              readOnly
              value={props.regulationDetails?.regulationScores.filter(score => score.ScoreType === ScoreType.RegulatoryRisk)[0]?.ScoreValue?.toString() || ''}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationScoringEngineeringImpactScore)}
              readOnly
              value={props.regulationDetails?.regulationScores.filter(score => score.ScoreType === ScoreType.EngineeringImpact)[0]?.ScoreValue?.toString() || ''}
            />
            <TextField
              label={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritizationScoringSalesBenefitScore)}
              readOnly
              value={props.regulationDetails?.regulationScores.filter(score => score.ScoreType === ScoreType.SalesBenefit)[0]?.ScoreValue?.toString() || ''}
            />
          </Stack>
        ) }
      </Stack.Item>


    </Stack>
  );

};
