import React, {
  useCallback, useEffect
} from 'react';

import {
  PanelType, Stack, TextField
} from '@fluentui/react';
import {
  useForm, Controller, FormProvider
} from 'react-hook-form';
import intl from 'react-intl-universal';

import { LocIds } from '../../../../common/Globalization/IntlEnum';
import {
  TextareaControl, TrustPanel, ViewLayout
} from '../../../../components';
import { FormPanelMode } from '../../../../components/TrustPanel/types';
import { Requirement } from '../../../../models/RegulationManagement/Requirement';
import {
  useAsync, UseAsyncHookStatus
} from '../../../../utils';


export interface RequirementDetailsPanelProps {
    isOpen: boolean;
    editPanelState: string;
    requirementGuid?: string;
    requirementTitle?: string;
    requirementBody?: string;
    onDismiss: () => void;
    getRequirementDetailsApi?: (requirementGuid: string) => Promise<Requirement>;
}

type RequirementForm = {
    requirementTitle: string;
    requirementBody: string;
};

export const RequirementDetailsPanel: React.FC<RequirementDetailsPanelProps> = (props) => {
  const { requirementGuid = '', editPanelState, requirementTitle, requirementBody, getRequirementDetailsApi } = props;

  const textAreaStyles = {
    height: 200,
  };

  // Initialize react-hook-form
  const formHandlers = useForm<RequirementForm>({
    defaultValues: {
      requirementTitle: '',
      requirementBody: ''
    }
  });

  const { control, setValue, formState: { errors } } = formHandlers;

  // Fetching requirement data
  const fetchRequirementDetails = useCallback(async (): Promise<Requirement> => {
    if (getRequirementDetailsApi) {
      return await getRequirementDetailsApi(requirementGuid);
    }

    return {
      Guid: '1a2b3c4d-5e6f-7g8h-9i0j-k1l2m3n4o5p6',
      Title: requirementTitle ? requirementTitle : '',
      Description: requirementBody ? requirementBody : ''
    };
  }, [requirementGuid, getRequirementDetailsApi]);

  const { data: requirementData, status } = useAsync<Requirement | undefined>(fetchRequirementDetails, undefined);

  // Update form values when data loads
  useEffect(() => {
    if (requirementData) {
      setValue('requirementTitle', requirementData.Title);
      setValue('requirementBody', requirementData.Description);
    }
  }, [requirementData, setValue]);

  return (
    <TrustPanel
      headerText={intl.get(LocIds.Components.RequirementDetail)}
      isOpen={props.isOpen}
      primaryActionProps={{
        text:
                    editPanelState === FormPanelMode.Edit ?
                      intl.get(LocIds.Action.Save) :
                      intl.get(LocIds.Action.Create),
        onClick: props.onDismiss,
      }}
      type={PanelType.medium}
      onDismiss={props.onDismiss}>
      <ViewLayout
        isError={status === UseAsyncHookStatus.Fail}
        isLoaded={!!requirementData && status === UseAsyncHookStatus.Success}>
        { requirementData && (
          <FormProvider {...formHandlers}>

            <Stack className='requirementDetailPanel'
              tokens={{
                childrenGap: 12
              }}>
              <Controller
                control={control}
                name='requirementTitle'
                render={({ field }) => (
                  <TextField
                    {...field}
                    errorMessage={errors.requirementTitle?.message}
                    label={intl.get(LocIds.RegulationManagement.AddRequirementTitleLabel)}
                    required
                    validateOnFocusOut
                    validateOnLoad={false}
                  />
                )}
              />
              <Controller
                control={control}
                name='requirementBody'
                render={({ field }) => (
                  <TextareaControl
                    {...field}
                    errorMessage={errors.requirementBody?.message}
                    label={intl.get(LocIds.RegulationManagement.AddRequirementDetailsLabel)}
                    multiline={true}
                    placeholder={intl.get(LocIds.RegulationManagement.AddRequirementDetailsPlaceholder)}
                    style={textAreaStyles}
                  />
                )}
              />
            </Stack>
          </FormProvider>
        ) }
      </ViewLayout>
    </TrustPanel>
  );
};
