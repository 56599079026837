export interface RegulationContact {
    RegulationGuid: string;

    // TODO: figure out how we want to resolve the contact GUID since the dbo.Account table isn't exposed as an OData API
    AccountGuid: string;

    ContactTypeId: RegulationContactType;

    // we might not need cardinality here (still in the process of determining that). Mostly cardinality is a DB-level mechanism. But it could have uses such as determining in the UI how to display things based off of the cardinality. So for now I am leaving this in.
    RegulationContactCardinality: Cardinality;
}

interface ContactType {
    contactType: RegulationContactType;
}

export enum RegulationContactType {
    // Multiple Cardinality
    Other = 'Other',
    Prioritization = 'Prioritization',
    SMTOther = 'SMTOther',

    // Singular Cardinality
    PrimaryCelaContact = 'PrimaryCelaContact',
    EnDProductCelaContact = 'EnDProductCelaContact',
    CnAIContact = 'CnAIContact',
    CnAIProductCelaContact = 'CnAIProductCelaContact',
    MicrosoftContact = 'MicrosoftContact',
    PrioritizationSignoffCela = 'PrioritizationSignoffCela',
    PrioritizationSignoffEngineering = 'PrioritizationSignoffEngineering',
    PrioritizationSignoffSales = 'PrioritizationSignoffSales',
    SMTCelaContact = 'SMTCelaContact',
    SMTEngineeringContact = 'SMTEngineeringContact',
    FrontlineCelaContact = 'FrontlineCelaContact',
    EnDContact = 'EnDContact',
}

enum Cardinality {
    OneToOne,
    OneToMany
}
