export interface RegulationScore {
    ScoreType: ScoreType;
    ScoreValue?: number;
}

export enum ScoreType {
    RegulatoryRisk,
    FinalPrioritization,
    SalesBenefit,
    EngineeringImpact
}
