export interface RegulationTrustAnalysis {
    TrustAnalysisType: TrustAnalysisType;
    TrustAnalysisValue: TrustAnalysis
}

export enum TrustAnalysisType {
    M365,
    CnAI
}

export enum TrustAnalysis {
    Completed = 0,
    InProgress = 1,
    NotApplicable = 2,
    NotStarted = 3,
    Approved = 4,
}
