/* eslint-disable i18next/no-literal-string */
import React from 'react';
import './style.less';

import intl from 'react-intl-universal';


import { LocIds } from '../../../../common/Globalization/IntlEnum';
import {
  BaseCardView,
  PageLayout,
  TwoColumnLayout, ViewLayout
} from '../../../../components';
import { RequirementList } from '../../components/RequirementList/RequirementList';

const ExtractedRequirements: React.FC = () => {

  const regulation = {
    RegulationGuid: '123e4567-e89b-12d3-a456-426614174000',
    Name: 'Azure + Dynamics 365 + Online Services - Public & Government SOC 1 Type II Report (10-01-2023 to 09-30-2024)',
    Description: `Section 1: Independent Service Auditor’s 
Report 
Microsoft Corporation 
One Microsoft Way  
Redmond, WA 98052-6399 
Scope 
We have examined the description of the Azure1 system of Management of Microsoft Corporation (the “Service 
Organization” or “Microsoft”) for processing user entities’ transactions throughout the period October 1, 2023 
to September 30, 20242, included in section 3, “Management of Microsoft’s Description of its Azure System” 
(the “Description”), and the suitability of the design and the operating effectiveness of controls included in the 
Description to achieve the related control objectives stated in the Description, based on the criteria identified in 
the management of Microsoft’s assertion. The controls and control objectives included in the Description are 
those that the management of Microsoft believes are likely to be relevant to user entities’ internal control over 
financial reporting, and the Description does not include those aspects of the Azure system that are not likely 
to be relevant to user entities’ internal control over financial reporting. 
The information in section 5, “Other Information Provided by Management of Microsoft” is presented by 
management of Microsoft to provide additional information and is not a part of the management of Microsoft’s 
Description of its Azure system made available to user entities during the period October 1, 2023 to September 
30, 2024. Content on the webpages directed by all the links and information in section 5 have not been subjected 
to the procedures applied in the examination of the Description of the Azure system and of the suitability of the 
design and operating effectiveness of controls to achieve the related control objectives stated in the Description 
of the Azure system and, accordingly, we express no opinion on it. 
The Description indicates that certain control objectives specified in the Description can be achieved only if 
complementary user entity controls contemplated in the design of Microsoft’s controls are suitably designed and 
operating effectively, along with related controls at Microsoft. Our examination did not extend to such 
complementary user entity controls, and we have not evaluated the suitability of the design or operating 
effectiveness of such complementary user entity controls. 
Service Organization’s Responsibilities 
In section 2, “Management of Microsoft’s Assertion,” management of Microsoft has provided an assertion about 
the fairness of the presentation of the Description and the suitability of the design and operating effectiveness 
of the controls to achieve the related control objectives stated in the Description. Management of Microsoft is 
1 Azure comprises of in-scope services and offerings for Microsoft Azure, Microsoft Dynamics 365, and Microsoft datacenters in the Azure and 
Azure Government cloud environments. 
2 In-scope services and offerings and coverage periods are defined in the Azure and Azure Government Report Scope and Boundary, and Internal 
Supporting Services subsections in section 3 of this SOC 1 report. In-scope datacenters, edge sites, and coverage periods are defined in the 
Regions Covered by this Report subsection in section 3 of this SOC 1 report. 
1 
responsible for preparing the Description and its assertion, including the completeness, accuracy, and method 
of presentation of the Description and the assertion, providing the services covered by the Description, 
specifying the control objectives and stating them in the Description, identifying the risks that threaten the 
achievement of the control objectives, selecting the criteria stated in the assertion, and designing, implementing, 
and documenting controls that are suitably designed and operating effectively to achieve the related control 
objectives stated in the Description.  `,
    Categories: 'Finance, Compliance',
    DocumentUrl: 'https://example.com/regulation.pdf',
    CreatedDate: new Date('2024-01-01'),
    DueByDate: new Date('2024-12-31')
  };

  const regulationHeader = (<p className={'requirement_Title'}>{ regulation.Name }</p>);

  const regulationBody = (
    <BaseCardView>
      { regulation.Description }
    </BaseCardView>
  );

  return (
    <PageLayout
      pageTitle={intl.get(LocIds.RegulationManagement.ExtractedRequirementsPageTitle)}>
      <TwoColumnLayout asideMinWidth={560}
        disableHeaderFlex={true}
        resizable={true}
        onRenderHeader={() => regulationHeader}
        onRenderSidebar={() => regulationBody}>
        <ViewLayout isError={false} isLoaded={true}>
          <RequirementList />
        </ViewLayout>
      </TwoColumnLayout>
    </PageLayout>
  );
};

export default ExtractedRequirements;
