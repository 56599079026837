import { isNil } from 'lodash';

import {
  CreateRegulationRequest,
  CreateRegulationResponse,
  Regulation,
  RegulationCatalogItem
} from '../../models';

import { RegulationContact } from '../../models/RegulationManagement/RegulationContact';
import { RegulationDetails } from '../../models/RegulationManagement/RegulationDetails';
import { RegulationImpact } from '../../models/RegulationManagement/RegulationImpact';
import { RegulationScore } from '../../models/RegulationManagement/RegulationScore';
import { RegulationScoringRationale } from '../../models/RegulationManagement/RegulationScoringRationale';
import { RegulationStatus } from '../../models/RegulationManagement/RegulationStatus';
import { RegulationTrustAnalysis } from '../../models/RegulationManagement/RegulationTrustAnalysis';
import { RegulationUserPermissions } from '../../models/RegulationManagement/RegulationUserPermissions';
import {
  get, post
} from '../OdataApiService';
import { buildODataQuery } from '../utils/odataQueryBuilder';

// Processor API endpoint constants
export const POST_ADD_REGULATION_API = '/AddRegulationActionRequest';
export const POST_USER_PERMISSION_API = '/GetRegulationUserPermissionsRequest';

// Data API endpoint constants
export const GET_REGULATION_DATA = '/data/Regulation';
export const GET_REGULATION_CONTACT_DATA = '/data/RegulationContact';
export const GET_REGULATION_CONTACT_TYPE_DATA = '/data/RegulationContactType';
export const GET_REGULATION_IMPACT_DATA = '/data/RegulationImpact';
export const GET_REGULATION_SCORE_DATA = '/data/RegulationScore';
export const GET_REGULATION_SCORING_RATIONALE_DATA = '/data/RegulationScoringRationale';
export const GET_REGULATION_STATUS_DATA = '/data/RegulationStatus';
export const GET_REGULATION_TRUST_ANALYSIS_DATA = '/data/RegulationTrustAnalysis';
export const GET_REGULATION_CATALOG_DATA = '/data/RegulationCatalog';

/** The Regulation Management API */
export namespace RegulationManagementApi {

  /**
   * Adds a new regulation.
   * @param {CreateRegulationRequest} request - The request to create the new Regulation.
   * @returns {CreateRegulationResponse} The response from the backend Processor
   * @see CreateRegulationRequest
   * @see CreateRegulationResponse
   */
  export const addNewRegulationAsync = async (request: CreateRegulationRequest): Promise<CreateRegulationResponse> => {
    const responseFromApi = await post<CreateRegulationRequest, CreateRegulationResponse>(POST_ADD_REGULATION_API, request);
    return responseFromApi;
  };

  /**
 * Gets the regulation permissions for a user. This is used to determine if a user has the ability to edit and/or delete regulations.
 * @returns {RegulationUserPermissions} The response from the backend processor with the user's permissions.
 * @see RegulationUserPermissions
 */
  export const getRegulationUserPermissionsAsync = async (): Promise<RegulationUserPermissions> => {
    const responseFromApi = await post<any, RegulationUserPermissions>(POST_USER_PERMISSION_API, {});
    return responseFromApi;
  };

  /**
   * Gets Regulations from the backend.
   * @param RegulationGuid - If specified, will add a filter to the OData query to only return results for the particular GUID.
   * @returns {Regulation[]} The Regulation(s) returned from the query.
   */
  export const getRegulations = async (RegulationGuid?: string): Promise<Regulation[]> => {
    const odataConfig: any = {};

    if (!isNil(RegulationGuid)) {
      odataConfig.filter.RegulationGuid = {
        value: RegulationGuid,
        type: 'guid'
      };
    }

    const odataQuery = buildODataQuery(odataConfig);

    const config = {
      odataQuery
    };

    return await get<Regulation[]>(
      GET_REGULATION_DATA,
      config
    );
  };

  /**
   * Gets Regulation Catalog Items from the backend. RegulationCatalogItem is a SQL View of the Regulation table.
   * @param RegulationGuid - If specified, will add a filter to the OData query to only return results for the particular GUID.
   * @returns {RegulationCatalogItem[]} The RegulationCatalogItem(s) returned from the query.
   * @see RegulationCatalogItem
   */
  export const getRegulationCatalog = async (RegulationGuid?: string): Promise<RegulationCatalogItem[]> => {
    const odataConfig: any = {};

    if (!isNil(RegulationGuid)) {
      odataConfig.filter = {
        RegulationGuid: {
          value: RegulationGuid,
          type: 'guid'
        }
      };
    }

    const odataQuery = buildODataQuery(odataConfig);

    const config = {
      odataQuery
    };

    return await get<RegulationCatalogItem[]>(
      GET_REGULATION_CATALOG_DATA,
      config
    );
  };

  /**
 * Gets the full set of details for a Regulation from the backend OData API.
 * @param RegulationGuid - Required to be specified. This will add a filter to the OData query to only return results for the particular Regulation.
 * @returns {RegulationDetails} The RegulationDetails returned from the query.
 * @see RegulationCatalogItem
 */
  export const getRegulationDetails = async (RegulationGuid: string): Promise<RegulationDetails> => {
    const odataConfig: any = {};

    odataConfig.filter = {
      RegulationGuid: {
        value: RegulationGuid,
        type: 'guid'
      }
    };

    const odataQuery = buildODataQuery(odataConfig);

    const config = {
      odataQuery
    };

    const response: Regulation[] = await get<Regulation[]>(GET_REGULATION_CONTACT_DATA, config);

    // Run all get calls concurrently
    const [
      regulationData,
      regulationContacts,
      regulationImpacts,
      regulationScores,
      regulationScoringRationales,
      regulationStatusData,
      regulationTrustAnalysisData
    ] = await Promise.all([
      get<Regulation[]>(GET_REGULATION_DATA, config), // Note: while we specify this as a Regulation array, in the return from this method we will only get the first element. It seems there isn't a way to query the OData API
      get<RegulationContact[]>(GET_REGULATION_CONTACT_DATA, config),
      get<RegulationImpact[]>(GET_REGULATION_IMPACT_DATA, config),
      get<RegulationScore[]>(GET_REGULATION_SCORE_DATA, config),
      get<RegulationScoringRationale[]>(GET_REGULATION_SCORING_RATIONALE_DATA, config),
      get<RegulationStatus[]>(GET_REGULATION_STATUS_DATA, config),
      get<RegulationTrustAnalysis[]>(GET_REGULATION_TRUST_ANALYSIS_DATA, config)
    ]);

    const regulationDetails: RegulationDetails = {
      regulation: regulationData[0],
      regulationContacts,
      regulationImpacts,
      regulationScores,
      regulationScoringRationales,
      regulationStatusData,
      regulationTrustAnalyisData: regulationTrustAnalysisData
    };

    return regulationDetails;
  };
}
