import React, {
  FC,
  ReactNode
} from 'react';

import { Link } from '@fluentui/react';
import './LabelValue.less';

import classNames from 'classnames';

import { CopyButton } from '../CopyButton';


interface IProps {
    label: ReactNode;
    value: ReactNode;
    direction?: 'row' | 'column';
    labelWidth?: string;
    canCopy?: boolean;
    size?: LabelSize;
    bold?: boolean;
    link?: string;
}

export enum LabelSize {
  SMALL,
  MEDIUM
}

export const LabelValue: FC<IProps> = ({ label, value, direction = 'row', labelWidth,
  canCopy = false, size = LabelSize.SMALL, bold = false, link }: IProps) => {

  const directionClass = `label-value--${direction}`;

  const labelClass = classNames('label-value', `label-value--${direction}`,
    {
      'label-value--medium': size === LabelSize.MEDIUM,
      'label-value--bold': bold
    });

  const labelStyles = labelWidth ? {
    minWidth: labelWidth
  } : {};

  const title = typeof value === 'string' ? value : undefined;


  return (
    <div className={labelClass}>
      <span className='text-14 label-value__label' style={labelStyles}>{ label }</span>
      <div className='label-value__value-wrapper'>
        <span className='text-14 label-value__value' title={title}>
          { link ? (
            <Link href={link} target='_blank'>
              { value }
            </Link>
          ) : (
            value
          ) }
        </span>
        { canCopy && (
          <span className='label-value__copyIcon'>
            <CopyButton text={value}/>
          </span>
        ) }
      </div>
    </div>
  );
};
