/* eslint-disable i18next/no-literal-string */


import React, { useCallback } from 'react';


import {
  CommandBar, Stack
} from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import intl from 'react-intl-universal';


import { RequirementDetailsPanel } from './RequirementDetailsPanel';

import { LocIds } from '../../../../common/Globalization/IntlEnum';
import { ViewLayout } from '../../../../components';
import { FormPanelMode } from '../../../../components/TrustPanel/types';
import { useAppDispatch } from '../../../../redux/reducer';
import { RequirementCard } from '../RequirementCard';


export const RequirementList: React.FC = () => {
  const dispatch = useAppDispatch();

  const [isNewPanelOpen, { setTrue: openNewPanel, setFalse: closeNewPanel }] = useBoolean(false);

  const onNewRequirement = useCallback(() => {

    openNewPanel();
  }, [dispatch]);

  const addButton = [
    {
      key: 'newItem',
      text: 'Add requirement',
      iconProps: {
        iconName: 'Add'
      },
      onClick: onNewRequirement,
    },
  ];

  const listJsx = (
    <Stack>
      <RequirementCard requirement={{
        Guid: '1a2b3c4d-5e6f-7g8h-9i0j-k1l2m3n4o5p6',
        Title: 'Requirement 1: Evidence',
        Description: `Performing procedures to obtain evidence about the fairness of the presentation of the Description and the 
suitability of the design and operating effectiveness of the controls to achieve the related control objectives 
stated in the Description, based on the criteria in management’s assertion. `,
      }}
      />
      <RequirementCard requirement={{
        Guid: '1a2b3c4d-5e6f-7g8h-9i0j-k1l2m3n4o5p6',
        Title: 'Requirement 2: Testing',
        Description: `Testing the operating effectiveness of those controls that management considers necessary to provide 
reasonable assurance that the related control objectives stated in the Description were achieved. `,
      }}
      />
      <RequirementCard requirement={{
        Guid: '1a2b3c4d-5e6f-7g8h-9i0j-k1l2m3n4o5p6',
        Title: 'Requirement 3',
        Description: 'This is the description for Requirement 3.',
      }}
      />
      <RequirementCard requirement={{
        Guid: '1a2b3c4d-5e6f-7g8h-9i0j-k1l2m3n4o5p6',
        Title: 'Requirement 1',
        Description: 'This is the description for Requirement 1.',
      }}
      />
      <RequirementCard requirement={{
        Guid: '1a2b3c4d-5e6f-7g8h-9i0j-k1l2m3n4o5p6',
        Title: 'Requirement 2',
        Description: 'This is the description for Requirement 2.',
      }}
      />
      <RequirementCard requirement={{
        Guid: '1a2b3c4d-5e6f-7g8h-9i0j-k1l2m3n4o5p6',
        Title: 'Requirement 3',
        Description: 'This is the description for Requirement 3.',
      }}
      />
    </Stack>
  );


  return (
    <ViewLayout
      emptyMessage={intl.get(LocIds.Evidence.EvidenceListIsEmpty)}
      isEmpty={false}
      isError={false}
      isLoaded={true}>
      <div style={{
        alignSelf: 'flex-start'
      }}>
        <CommandBar items={addButton} />
      </div>
      { listJsx }
      <RequirementDetailsPanel editPanelState={FormPanelMode.New}
        isOpen={isNewPanelOpen}
        onDismiss={closeNewPanel}
      />
    </ViewLayout>
  );
};
