import React, {
  useEffect, useState
} from 'react';

import {
  Pivot, PivotItem, Spinner
} from '@fluentui/react';
import { isNil } from 'lodash';


import intl from 'react-intl-universal';
import { useParams } from 'react-router-dom';


import { MainRegulationDetails } from './MainRegulationDetails';

import { RegulationExecutionDetails } from './RegulationExecutionDetails';
import { RegulationPrioritizationDetails } from './RegulationPrioritizationDetails';

import { LocIds } from '../../../../common/Globalization/IntlEnum';
import { PageLayout } from '../../../../components';
import { RegulationDetails } from '../../../../models/RegulationManagement/RegulationDetails';
import { RegulationManagementApi } from '../../../../services/AthenaRestApiService/RegulationManagmentApiService';
import {
  notify, showErrorMsg
} from '../../../../utils';

const RegulationDetailsPage: React.FC = () => {

  /**
   * The regulation for the regulationId that we are looking at will come from the regulation in the URL when the link is clicked.
   */
  const { regulationId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [regulationDetails, setRegulationDetails] = useState<RegulationDetails>();
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    if (isNil(regulationId)) {
      setHasError(true);
    } else {
      getRegulationDetails();
    }

  }, []);

  /**  Gets the regulations from the Regulation Management OData API. */
  const getRegulationDetails = async () => {
    try {
      if (!isNil(regulationId)) {
        const response = await RegulationManagementApi.getRegulationDetails(regulationId);
        setRegulationDetails(response);
      } else {
        setHasError(true);
      }
    } catch (error) {
      showErrorMsg(error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (hasError) {
    notify.error(intl.get(LocIds.RegulationManagement.RegulationDetailsErrorMessage));
    return <></>;
  }

  return (
    <PageLayout pageTitle={intl.get(LocIds.RegulationManagement.RegulationDetails)}>
      <Pivot>
        <PivotItem headerText={intl.get(LocIds.RegulationManagement.RegulationDetails)} itemKey='regulationDetails'>
          <MainRegulationDetails regulationDetails={regulationDetails} />
        </PivotItem>
        <PivotItem headerText={intl.get(LocIds.RegulationManagement.RegulationDetailsPrioritization)} itemKey='regulationPrioritizationDetails'>
          <RegulationPrioritizationDetails regulationDetails={regulationDetails} />
        </PivotItem>
        <PivotItem headerText={intl.get(LocIds.RegulationManagement.RegulationDetailsExecution)} itemKey='regulationExecutionDetails'>
          <RegulationExecutionDetails regulationDetails={regulationDetails} />
        </PivotItem>
      </Pivot>
    </PageLayout>
  );
};

export default RegulationDetailsPage;
