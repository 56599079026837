import React, { useState } from 'react';

import {
  FontIcon, Label, Stack, ILabelStyles
} from '@fluentui/react';

interface CollapsableLabelProps {
  label: string;
  isOpen: boolean;
  onToggle: () => void;
  labelStyles?: ILabelStyles;
}

export const CollapsableLabel: React.FC<CollapsableLabelProps> = (props) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const stackStyles = {
    root: {
      marginTop: '20px'
    }
  };

  return (

    <Stack horizontal
      styles={stackStyles}
      tokens={{
        childrenGap: 20
      }}
      verticalAlign='center'>
      <Label
        styles={props.labelStyles}
        onClick={props.onToggle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        { props.label }
      </Label>
      <FontIcon iconName={props.isOpen === isHovered ? 'ChevronUp' : 'ChevronDown'} />
    </Stack>
  );
};
