
export interface AddIterationRequest<DateType = string> {
  IterationGuid?: string;
  setName: string;
  setPeriodReviewStartDate: DateType;
  setPeriodReviewEndDate: DateType;
  setStartDate: DateType;
  setClassificationReviewDueDate: DateType;
  setGroupReviewDueDate: DateType;
  setManagerReviewDueDate: DateType;
  setRevocationReviewDueDate: DateType;
  addUarAuditEvents: UarAuditEvent[]
}

export interface EditIterationRequest<DateType = string> {
  userAccessReviewGuid: string;
  setName?: string;
  setPeriodReviewStartDate?: DateType;
  setPeriodReviewEndDate?: DateType;
  setStartDate?: DateType;
  setClassificationReviewDueDate?: DateType;
  setGroupReviewDueDate?: DateType;
  setManagerReviewDueDate?: DateType;
  setRevocationReviewDueDate?: DateType;
  addUarAuditEvents: UarAuditEvent[];
  removeUarAuditEvents: UarAuditEvent[]
}

export interface UarAuditEvent {
  AuditEventGuid: string;
  ServiceTreeIds: string[]
}

export interface IterationAudit extends UarAuditEvent {
  Name: string;
}

export interface UarIteration {
  Id: string;
  UserAccessReviewGuid: string;
  Name: string;
  State: UarIterationStageName;
  GenericState: UarIterationGenericStateName;

  PeriodReviewStartDate: string;
  PeriodReviewEndDate: string;

  StartDate: string;

  // TODO Remove ReviewDate
  ReviewDate: string;
  CompletionDate: string | null;

  Reviews: UarIterationStage[];
  UarAuditEvents: IterationAudit[];
}

export interface UarIterationStage {
  StageType: UarIterationStageName;
  Progress: number;
  StartDate: string;
  DueDate: string;
}

export enum UarIterationStageName {
  ClassificationReview = 'ClassificationReview',
  GroupReview = 'GroupReview',
  ManagerReview = 'ManagerReview',
  RevocationReview = 'RevocationReview',
  ManualReview = 'ManualReview',
  Completed = 'Completed'
}

export enum UarIterationGenericStateName {
  NotStarted = 'NotStarted',
  ClassificationReview = 'ClassificationReview',
  GroupReview = 'GroupReview',
  ManagerReview = 'ManagerReview',
  RevocationReview = 'RevocationReview',
  Completed = 'Completed',
}

export enum AthenaIterationStageName {
  ClassificationReview='Classification',
  ClassificationException='ClassificationException',
  Group = 'Group',
  Manager = 'Manager',
  PermissionRevocation = 'PermissionRevocation',
  PermissionRevocationException = 'PermissionRevocationException',
  ManualReview = 'Manual',
}

export enum IterationAction {
  Add,
  Edit,
}

export type UarEditIteration = AddIterationRequest<Date | undefined> | EditIterationRequest<Date | undefined> | undefined;

export interface UarIterationFilters {
  Search?: string;
  GenericState?: string[];
}
