export interface RegulationScoringRationale {
    ScoringRationaleType: ScoringRationaleType
    ScoringRationaleValue: string;
}

export enum ScoringRationaleType {
    SalesBenefit,
    EngineeringImpact,
    RegulatoryRisk
}
