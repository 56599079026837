import {
  utcToZonedTime,
  format
} from 'date-fns-tz';

export function dateToLocalString(date:string|Date):string {
  return dateToString(date, null);
}

export function dateToString(date:string|Date, timeZone:string|null):string {
  const timeZoneToUse = timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  return format(utcToZonedTime(date, timeZoneToUse), 'yyyy-MM-dd HH:mm:ss zzz', {
    timeZone: timeZoneToUse
  });
}
