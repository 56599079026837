export interface RegulationStatus {
    StatusType: StatusType;
    StatusValue: Status
}

export enum StatusType {
    ImplementationGuidance,
    ProductRequirements,
    EngineeringImplementation
}

enum Status {
    Completed = 0,
    InProgress = 1,
    NotApplicable = 2,
    NotStarted = 3
}
